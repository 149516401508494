/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    msg: {
      type: String,
      default: function _default() {
        return '考勤打卡成功';
      }
    },
    time: {
      type: String,
      default: function _default() {
        return ' ';
      }
    }
  },
  data: function data() {
    return {
      img_src: require('@/assets/face/success_img.png'),
      show: true,
      message: '',
      ctn: '人脸照片或档案照片已采集',
      img: require('@/assets/face/position_success.png')
    };
  },
  watch: {
    msg: {
      handler: function handler(newMsg) {
        this.message = newMsg;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    closeDialg: function closeDialg() {
      this.$emit('closeDialog');
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};